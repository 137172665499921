import React from 'react'
import { ShareButtons } from 'react-share'

const { TwitterShareButton } = ShareButtons

export default function TwitterShare(props) {
  return (
    <TwitterShareButton url={ props.currentUrl }
      title={ props.title }
      // via="studioflama"
      // hashtags={['insert your hashtags here']}
      className="twitter"
    />
  )
}

TwitterShare.defaultProps = {
  currentUrl: window.location.href,
}

const hasCookie = (cookieName, cookieId) => {
  const name = `paxe.${cookieName}[${cookieId}]=`
  const decodedCookie = decodeURIComponent(document.cookie).split(';')

  return decodedCookie
    .find(cookie => cookie.replace(/\s+/, '').indexOf(name) === 0)
}

const setCookie = (cookieName, cookieId, value = true) => {
  document.cookie = `paxe.${cookieName}[${cookieId}]=${value};`
}

export {
  hasCookie,
  setCookie,
}

import spawn from '../helpers/Spawner'
import Feedback from '../components/Feedback'
import QuizOptions from '../components/QuizOptions'

const components = {
  Feedback,
  QuizOptions,
}

const behaviors = {
}

spawn(components, behaviors)

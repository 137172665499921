import React from 'react'

export default class QuizOptions extends React.Component {
  state = {
    value: ''
  }

  options = [
    'major-negative',
    'negative',
    'minor-negative',
    'neutral',
    'minor-positive',
    'positive',
    'major-positive'
  ]

  check = (option) => {
    return (event) => {
      const value = this.options.indexOf(option)
      this.setState({ value })
    }
  }

  statusClassFor = (option) => {
    const { value } = this.state

    if (this.options[value] == option) {
      return '-checked'
    }
  }

  render() {
    const { question } = this.props
    const { value } = this.state

    return (
      <div className="options">
        {this.options.map((option) => (
          <div key={option} onClick={this.check(option)} className={`option -${option} ${this.statusClassFor(option)}`}>
            <label className="label" />
          </div>
        ))}

        <input type="hidden" value={value} name={`content_quiz[question_${question}]`} />
      </div>
    )
  }
}

import React, { Component } from 'react'
import Thumbs from '../Thumbs'
import FacebookShare from '../FacebookShare'
import TwitterShare from '../TwitterShare'

import { hasCookie, setCookie } from '../../helpers/CookieHelper'

export default class Feedback extends Component {
  state = {
    feedback: null,
    feedbackOnCookie: null,
  }

  headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': this.props.authenticityToken,
  }

  // RFC 5322 compliant - simplified
  emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/

  valid = () => {
    return this.state.valid !== false
  }

  componentWillMount() {
    const cookie = hasCookie(this.props.type, this.props.article)

    if (cookie && cookie.match(/.*true$/)) {
      this.setState({ feedbackOnCookie: true })
    } else if (cookie && cookie.match(/.*false$/)) {
      this.setState({ feedbackOnCookie: false })
    }
  }

  handleThumbsUp = () => {
    this.setState({ feedback: true })

    if (!hasCookie(this.props.type, this.props.article)) {
      setCookie(this.props.type, this.props.article, true)

      fetch(`${this.props.feedbacksPath}/${this.props.article}`, {
        method: 'put',
        credentials: 'same-origin',
        headers: this.headers,
        body: JSON.stringify({
          type: this.props.type,
        })
      })
      .catch(console.error)
    }
  }

  handleThumbsDown = () => {
    this.setState({
      feedback: false
    })

    setCookie(this.props.type, this.props.article, false)
  }

  handleChange = (field) => {
    return (event) => this.setState({
      [field]:  event.target.value
    })
  }

  validateEmail = (event) => {
    this.setState({
      valid: !!this.emailRegex.test(event.target.value)
    })
  }

  submitForm = (event) => {
    event.preventDefault()

    if (!this.valid() || !this.state.message || !this.state.sender) return

    if (this.valid()) {
      fetch(this.props.feedbacksPath, {
        method: 'post',
        credentials: 'same-origin',
        headers: this.headers,
        body: JSON.stringify({
          feedback: {
            message: this.state.message,
            sender: this.state.sender,
            likeable_id: this.props.article,
            likeable_type: this.props.type,
          }
        })
      })
      .catch(() => console.error("Error while sending feedback request."))

      this.setState({
        finished: true
      })
    }
  }

  renderEmailError = () => (
    <div className="field-error">
      Este email não é válido!
    </div>
  )

  renderForm = () => (
    <div className="feedback-form">
      Nos ajude a melhorar, deixe aqui seu feedback:
      <form
        action="/feedbacks" // feedbacks_path
        acceptCharset="UTF-8"
        method="post"
        onSubmit={ this.submitForm }
      >
        <input
          type="hidden"
          name="utf8"
          value="✓"
        />
        <div className="input textarea">
          <label htmlFor="feedback_message" className="label">
            Mensagem
          </label>
          <textarea
            name="feedback[message]"
            id="feedback_message"
            onChange={ this.handleChange('message') }
          />
        </div>
        <div className="input">
          <label htmlFor="feedback_sender" className="label">
            Seu Email
          </label>
          <input
            type="text"
            name="feedback[sender]"
            id="feedback_sender"
            className={ this.valid() ? '' : 'error' }
            onChange={ this.handleChange('sender') }
            onBlur={ this.validateEmail }
          />
        </div>
        {
          this.valid() ? '' : this.renderEmailError()
        }
        <input
          type="submit"
          name="commit"
          value="Enviar"
          className={ `outline-button${ this.valid() ? '' : ' -error'}` }
        />
      </form>
    </div>
  )

  renderThanks = () => (
    <div className="share-menu">
      Seu feedback foi salvo e será analisado pela nossa equipe. Agradecemos sua contribuição!
    </div>
  )

  renderShareOptions = () => (
    <div className="share-menu">
      Legal, compartilhe na sua rede:
      <div className="socials">
        <FacebookShare
          currentUrl={ this.props.url }
          description={this.props.title}
        />
        <TwitterShare
          currentUrl={ this.props.url }
          title={this.props.title}
        />
      </div>
    </div>
  )

  renderResponse = () => {
    if (this.state.finished) {
      // submitted feedback, thank the user
      return this.renderThanks()
    }

    if(this.state.feedback === null) {
      // yet to click on a thumb, render nothing
      return <div />
    }

    if (this.state.feedback) {
      // thumbs up, show share menu
      return this.renderShareOptions()
    } else {
      // thumbs down, show feedback form
      return this.renderForm()
    }
  }

  render = () => {
    const { feedback, feedbackOnCookie } = this.state
    return (
      <div className="feedback">
        Esse artigo foi útil? <Thumbs
          feedback={ feedback === null ? feedbackOnCookie : feedback }
          toggleUp={ this.handleThumbsUp }
          toggleDown={ this.handleThumbsDown }
        />
        { this.renderResponse() }
      </div>
    )
  }
}

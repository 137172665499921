import React from 'react'
import ReactDOM from 'react-dom'
import merge from 'lodash/merge'

// To spawn an <Example /> react component:
// <div data-component="Example"></div>

// To spawn an Example behavior:
// <div data-behavior="Example"></div>


export default function (componentList = {}, behaviorsList = {}) {

  document.addEventListener('DOMContentLoaded', () => {
    const components = document.querySelectorAll('[data-component]')
    // IE didn't implement .forEach, sadly
    for (let i = 0; i < components.length; ++i) {
      const component = components[i]
      const ComponentClass = componentList[component.dataset.component]

      if (typeof ComponentClass !== 'undefined') {
        let element = React.createElement(
          ComponentClass,
          merge(component.dataset, { children: component.innerHTML.trim() }))
          ReactDOM.render(element, component)
      } else {
        console.error(`${component.dataset.component} is undefined`)
      }
    }

    const behaviors = document.querySelectorAll('[data-behavior]')
    for (let i = 0; i < behaviors.length; ++i) {
      const behavior = behaviors[i]
      try {
        const BehaviorClass = behaviorsList[behavior.dataset.behavior]
        new BehaviorClass(behavior, window, document)
      } catch (e) {
        console.error(`${behavior.dataset.behavior} is undefined`)
      }
    }
  })
}

import React from 'react'
import { ShareButtons } from 'react-share'

const { FacebookShareButton } = ShareButtons

export default function FacebookShare(props) {
  return (
    <FacebookShareButton url={ props.currentUrl }
      quote={ props.description }
      className="facebook"
    />
  )
}

FacebookShare.defaultProps = {
  currentUrl: window.location.href,
}

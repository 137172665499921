import React from 'react'
import PropTypes from 'prop-types'

export default function Thumbs(props) {
  return (
    <div className="thumbs">
      <div className={`up${props.feedback ? ' -active' : ''}`}
        onClick={ props.toggleUp }
      />
      <div className={`down${props.feedback === false ? ' -active' : ''}`}
        onClick={ props.toggleDown }
      />
    </div>
  )
}

Thumbs.propTypes = {
  feedback: PropTypes.bool,
  toggleUp: PropTypes.func.isRequired,
  toggleDown: PropTypes.func.isRequired,
}

Thumbs.defaultProps = {
  toggleUp: () => {},
  toggleDown: () => {},
}
